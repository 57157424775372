import { getHeight } from './../helpers/getHeight.js';

export function accordions() {

	const accordionGroup = document.querySelectorAll('.accordion-group');

	accordionGroup.forEach((group) => {
		const accordionToggles = group.querySelectorAll('.accordion-trigger'),
			  accordionPanels = group.querySelectorAll('.accordion-panel'),
			  accordionOneAtATime = group.dataset.openOne,
			  accordionFirstOpen = group.dataset.firstOpen;

		const setAccordionPanelHeight = () => {
			accordionPanels.forEach((panel) => {
				getHeight(panel);
			});
		}

		const resetAccordions = () => {
			accordionToggles.forEach((toggle) => {
				toggle.setAttribute('aria-expanded', 'false');
			});
			accordionPanels.forEach((panel) => {
				panel.style.height = 0;
				panel.classList.remove('open');
			});
		}

		const openFirstAccordion = () => {
			const accordionToggleFirst = group.querySelector('.accordion-trigger'),
			      accordionPanelFirst = group.querySelector('.accordion-panel'),
				  accordionPanelFirstHeight = accordionPanelFirst.dataset.height;

			accordionToggleFirst.setAttribute('aria-expanded', true);
			accordionPanelFirst.style.height = accordionPanelFirstHeight + 'px';
			accordionPanelFirst.classList.add('open');
		}

		setAccordionPanelHeight();
		resetAccordions();
		
		if (accordionFirstOpen !== undefined) {
			openFirstAccordion();
		}

		accordionToggles.forEach((toggle) => {
			toggle.addEventListener('click', (e) => {
				const toggleBtn = e.target,
					   toggleExpanded = toggleBtn.getAttribute('aria-expanded'),
					   toggleId = toggleBtn.id,
				       togglePanel = document.getElementById('panel-' + toggleId),
				       togglePanelHeight = togglePanel.dataset.height;
				
				const expandAccordion = () => {
					toggleBtn.setAttribute('aria-expanded', true);
					togglePanel.style.height = togglePanelHeight + 'px';
					togglePanel.classList.add('open');
				}

				e.preventDefault();
				if (toggleExpanded === 'false') {
					if (accordionOneAtATime !== undefined) {
						resetAccordions();
						setTimeout(() => {
							expandAccordion();
						}, 50);
					}
					else {
						expandAccordion();
					}
				}
				else {
					if (accordionOneAtATime !== undefined) {
						resetAccordions();
					}
					else {
						toggleBtn.setAttribute('aria-expanded', false);
						togglePanel.style.height = 0;
						togglePanel.classList.remove('open');
					}
				}
			})
		});
	});
}