export function intersectionObserver() {
	const setInView = (entries, observer) => {
		entries.forEach((entry) => {
			const observeOnce = entry.target.dataset.observeOnce;
			if (observeOnce !== undefined) {
				if (entry.isIntersecting) {
					entry.target.classList.add('in-view');
					observer.unobserve(entry.target);
				}
			}
			else {
				if (entry.isIntersecting) {
					entry.target.classList.add('in-view');
				}
				else {
					entry.target.classList.remove('in-view');
				}
			}
		});
	};

	const itemOptions = {
		threshold: .5
	};

	const itemObserver = new IntersectionObserver(setInView, itemOptions);

	const items = document.querySelectorAll('[data-observe]');

	if (items) {
		items.forEach((item) => {
			itemObserver.observe(item);
		});
	}
}