import A11yDialog from 'a11y-dialog'

export function modal(dialogEl) {
	var dialog = new A11yDialog(dialogEl)
	
	dialog.on('show', function (event) {
	  const container = event.target
	  const target = event.detail.target
	  const opener = target.closest('[data-a11y-dialog-show]')
	})
}

