import { nav } from '../components/nav.js';

export default {
	init() {
		// JavaScript to be fired on all pages

		// nav
		nav();
	},
	finalize() {
		// JavaScript to be fired on all pages, after page specific JS is fired
	}
};
