import { intersectionObserver } from './../helpers/intersectionObserver.js';
import { modal } from './../components/modal.js';
import { accordions } from './../components/accordions.js';
import Swiper from 'swiper/bundle';

export default {
	init() {
		// in-view watcher
		intersectionObserver();

		// modals
		const modals = document.querySelectorAll('.dialog-container');
		if (modals) {
			modals.forEach((modalEl) => {
				modal(modalEl);
			});
		}

		// accordions
		const accordionGroup = document.querySelectorAll('accordion-group');
		if (accordionGroup) {
			accordions();
		}

		// carousels 
		const carousel1 = document.getElementById('carousel-1');
		const swiper1 = new Swiper(carousel1, {
			loop: true,
			pagination: {
				el: '.swiper-pagination',
				clickable: true
			},
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev',
			},
			a11y: {
				prevSlideMessage: 'Previous slide',
				nextSlideMessage: 'Next slide',
			},
		});
	},
	finalize() {
		// JavaScript to be fired on the home page, after the init JS
	},
};
