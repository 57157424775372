import { getHeight } from './../helpers/getHeight.js';

export function nav() {
	const
		mainNav = document.querySelector('.header-nav'),
		mobileNavToggle = document.querySelector('.nav-toggle'),
		subnavToggle = document.querySelectorAll('.subnav-toggle'),
		subnav = document.querySelectorAll('.nav-subnav'),
		navBreak = getComputedStyle(document.documentElement).getPropertyValue('--header-nav-break');

	let
		intViewportWidth = window.innerWidth;

	// reset nav for desktop/mobile
	const resetNav = () => {
		subnavToggle.forEach((toggle) => {
			toggle.setAttribute('aria-pressed', 'false');
		});
		subnav.forEach((nav) => {
			nav.setAttribute('aria-hidden', 'true');
			nav.style.height = '';
			getHeight(nav);
			nav.style.height = 0;
		});
	}

	// initial nav setup
	const setNav = () => {
		intViewportWidth = window.innerWidth;
		if (intViewportWidth <= navBreak) {
			mainNav.setAttribute('aria-hidden', 'true');
		}
		else {
			mainNav.removeAttribute('aria-hidden');
		}
		mobileNavToggle.setAttribute('aria-pressed', 'false');
		subnav.forEach((nav) => {
			getHeight(nav);
		});
		resetNav();
	}

	// mobile nav toggle
	const toggleMainNav = () => {
		mobileNavToggle.addEventListener('click', (e) => {
			if (e.target.getAttribute('aria-pressed') === 'true') {
				// toggle menu
				e.target.setAttribute('aria-pressed', 'false');
				mainNav.setAttribute('aria-hidden', 'true');
			}
			else {
				// toggle menu
				e.target.setAttribute('aria-pressed', 'true');
				mainNav.setAttribute('aria-hidden', 'false');
			}
		});
	}

	// subnav toggle for desktop/mobile
	const toggleSubnav = () => {
		const toggler = (target) => {
			if (target.getAttribute('aria-pressed') === 'true') {
				resetNav();
			}
			else {
				resetNav();
				const itemSubnav = target.nextElementSibling,
					itemSubnavItem = itemSubnav.querySelectorAll('.subnav-item');
				let itemSubnavHeight = itemSubnav.dataset.height;

				target.setAttribute('aria-pressed', 'true');
				itemSubnav.setAttribute('aria-hidden', 'false');
				itemSubnav.style.height = itemSubnavHeight + 'px';
			}
		}
		subnavToggle.forEach((toggle) => {
			toggle.addEventListener('click', (e) => {
				e.preventDefault();
				toggler(e.target);
			});
		});
	}

	// close open subnav if clicked outside of nav 
	document.addEventListener('click', function (e) {
		const headerNav = document.querySelector('.header-nav');
		let outsideClick = !headerNav.contains(e.target);
		if (outsideClick) {
			resetNav();
		}
	});

	window.onload = setNav;
	window.onresize = setNav;
	toggleMainNav();
	toggleSubnav();
}